//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import SuperAdminCompanyModal from '@/components/SuperAdminCompanyModal/SuperAdminCompanyModal.vue'
import UserAvatar from '@/components/UserAvatar'
import ROLES from '@/constants/roles'
import { truncateText } from '@/util/string'
export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu,
    SuperAdminCompanyModal,
  },
  data() {
    return {
      isMenuNavBarActive: false,
      isModalCompaniesActive: false,
      companies: [],
      newCompanies: [],
      companyName: '',
    }
  },
  computed: {
    hasCompanyValue() {
      return !this.$store.getters['me/isCompanyNull']
    },
    isAdminRelink() {
      return this.$strapi.user.role.type === ROLES.RELINK
    },
    names() {
      return this.$strapi.user.names
    },
    navBarName() {
      return this.$store.getters['me/showMenuTitle']
    },
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    ...mapState(['isNavBarVisible', 'isAsideMobileExpanded']),
  },
  async created() {
    this.companies = await this.getCompanies()
    this.newCompanies = await this.lastNewsCompanies()
    this.companyName = this.getCurrentCompanyName(this.companies)
  },
  methods: {
    menuToggleMobile() {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive
    },
    menuModalCompaniesActiveToggle() {
      this.isModalCompaniesActive = !this.isModalCompaniesActive
    },
    async logout() {
      if (this.isAdminRelink && this.$strapi.user.company) {
        await this.$repository.user.update(this.$strapi.user.id, {
          company: null,
        })
      }
      localStorage.removeItem('ask_to_confirm')
      this.$router.push('/salir')
    },
    async lastNewsCompanies() {
      return await this.$repository.company.getPilotCompanysForAdminRelik({
        active: 1,
        company_type: 2,
        per_page: 3,
        page: 1,
        search: '',
        _sort: 'created_at:DESC',
      })
    },
    async getCompanies() {
      return await this.$repository.company.findAllBasicInfo({
        active: 1,
        company_type: 2,
        _sort: 'created_at:DESC',
      })
    },
    async updateCompanyinAdmin(company) {
      await this.$repository.user.update(this.$strapi.user.id, {
        company: company.id,
      })
      location.replace('/inicio')
    },
    async deleteCompanyInAdmin() {
      await this.$repository.user.update(this.$strapi.user.id, {
        company: null,
      })
      location.replace('/inicio')
    },
    getCurrentCompanyName(companies) {
      const company = companies.find(
        (company) => company.id === this.$strapi.user?.company?.id
      )
      return company?.name || undefined
    },
    truncateText,
  },
}
