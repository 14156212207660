//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PROJECT from '@/constants/projects'
import { TYPES } from '@/constants/project-types'
import ProgressIndicator from '@/components/Shared/ProgressIndicator'
import RobotIcon from '~/assets/svg/robot-icon.svg'

export default {
  name: 'MatchPotentialTable',
  components: {
    RobotIcon,
    ProgressIndicator,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    sortOrder: {
      type: String,
      default: 'ASC',
    },
    sortField: {
      type: String,
      default: 'match',
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      projectTypeLabels: {
        [TYPES.RESKILLING]: this.$t('projects.types.reskilling'),
        [TYPES.UPSKILLING]: this.$t('projects.types.upskilling'),
        [TYPES.SELFDIAGNOSIS]: this.$t('projects.types.selfdiagnosis'),
        [TYPES.MODELS]: this.$t('projects.types.models'),
      },
    }
  },
  computed: {
    calculate() {
      return this.users.length === this.perPage
        ? `${this.users.length * this.currentPage - (this.perPage - 1)}-${
            this.users.length * this.currentPage
          }`
        : `${this.users.length}`
    },
  },
  methods: {
    getClassByStatus(status) {
      return PROJECT[status].class
    },
    getNameByStatus(status) {
      return PROJECT[status].name
    },
    isFinished(status) {
      return status === PROJECT.FINISHED.value
    },
    openProjectDetail(project) {
      project.status === PROJECT.DRAFT.value
        ? this.$router.push(`/mis-proyectos/nuevo?code=${project.code}`)
        : this.$router.push(`/mis-proyectos/${project.id}`)
    },
    onPageChange(page) {
      this.$emit('page-change', page)
    },
    onSort(field, order) {
      this.$emit('sort', { field, order })
    },
  },
}
