const Request = require('@/repositories/request')
const qs = require('qs')
const { encodeQueryParams } = require('~/util/query')

class EducationalArea extends Request {
  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$educational-areas'].find(stringifyParams)
  }

  withSkills(params) {
    return this.$strapi.$http.$get(
      `/educational-areas/skills?${encodeQueryParams(params)}`
    )
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  skillMoreRelevantsBeta(id, experienceId = 0, total) {
    return this.$strapi.$http.$get(
      `/educational-areas/${id}/${experienceId}/${
        total > 0 ? total : 30
      }/skill-more-relevants`
    )
  }
}

module.exports = EducationalArea
