//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PERMISSIONS from '@/constants/permissions'
import CompanySkillsInventoryCard from '@/components/Dashboard/Company/CompanySkillsInventoryCard'
import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline'
import { mapMutations } from 'vuex'

export default {
  components: { CompanySkillsInventoryCard, EmoticonSadOutline },
  async fetch() {
    try {
      this.$store.commit('loading', true)
      this.NUM_OPPORTUNITIES = this.isVisibleSkillsInventory ? 3 : 4
      this.opportunitiesUser = await this.fetchOpportunities()
      this.employeesCount = await this.getEmployeesWithExperiencesCount()
    } catch (e) {
      this.$buefy.notification.open({
        message: e.message,
        type: 'is-danger',
        autoclose: true,
        duration: 10000,
      })
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      opportunitiesUser: [],
      employeesCount: 0,
      NUM_OPPORTUNITIES: 0,
      showSeeMore: true,
    }
  },
  computed: {
    isVisibleSkillsInventory() {
      return this.$store.getters['me/companyPermissions'].includes(
        PERMISSIONS.SKILLS_INVENTORY
      )
    },
    isVisibleCompanyOpportunities() {
      return this.$store.getters['me/companyPermissions'].includes(
        PERMISSIONS.REQUIRED_OCCUPATIONS
      )
    },
    opportunities() {
      return this.opportunitiesUser.map((opportunity) => ({
        ...opportunity,
        subheader: '',
        title: opportunity.name,
        titleHighlight: opportunity.project_name
          ? `"${opportunity.project_name}"`
          : '',
        subtitle: this.$t('dashboard.complete.companySkills', [
          opportunity.matches,
        ]),
        imgSource: '/img/dashboard/opportunity.svg',
        buttonText: this.$t('dashboard.complete.buttons.opportunity'),
      }))
    },
  },
  methods: {
    ...mapMutations({
      setOpportunity: 'opportunity/setOpportunity',
    }),
    handleClickOpportunity(opportunity) {
      this.$router.push(`/empresas/ocupacion-requerida/${opportunity.id}`)
    },
    async fetchOpportunities() {
      return await this.$repository.me.getCompanyOpportunitiy({
        _limit: this.NUM_OPPORTUNITIES,
      })
    },
    async getEmployeesWithExperiencesCount() {
      return await this.$repository.company.getEmployeesWithExperiencesCount()
    },
  },
}
