//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatsCard from '@/components/DeliveryResults/StatsCard.vue'
import ColoredNotification from '@/components/Shared/ColoredNotification.vue'
import Button from '@/components/Shared/Button'
import ExperiencesSection from '~/components/DeliveryResults/ExperiencesSection.vue'
import ProjectsSection from '~/components/DeliveryResults/ProjectsSection.vue'
import TopSkillsSection from '~/components/DeliveryResults/TopSkillsSection.vue'
import OnGoingProjectsSection from '~/components/DeliveryResults/OnGoingProjectsSection.vue'

export default {
  components: {
    StatsCard,
    TopSkillsSection,
    OnGoingProjectsSection,
    ExperiencesSection,
    ProjectsSection,
    ColoredNotification,
    Button,
  },
  props: {
    reportData: {
      type: Object,
      default: () => ({}),
    },
    projectsByStatus: {
      type: Object,
      default: () => ({}),
    },
    topSkills: {
      type: Array,
      default: () => [],
    },
    onGoingProjects: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // Stats Data
    totalUsers() {
      return this.reportData?.totalUsers ?? 0
    },
    totalSkills() {
      return this.reportData?.totalSkills ?? 0
    },
    totalEducational() {
      return this.reportData?.totalEducational ?? 0
    },
    totalWork() {
      return this.reportData?.totalWork ?? 0
    },
    totalOther() {
      return this.reportData?.totalOther ?? 0
    },
    totalUsersWithExperiences() {
      return this.reportData?.totalUsersWithExperiences ?? 0
    },
    totalUsersWithSkills() {
      return this.reportData?.totalUsersWithSkills ?? 0
    },
    totalExperiences() {
      return this.totalEducational + this.totalWork + this.totalOther
    },
    averageExperiences() {
      if (!this.totalUsersWithExperiences) return 0
      return parseFloat(
        this.totalExperiences / this.totalUsersWithExperiences
      ).toFixed(2)
    },
    experienceDescription() {
      return `Total: ${this.numberWithThousands(this.totalExperiences)}`
    },
    averageSkills() {
      if (this.totalUsersWithSkills === 0) return 0
      return parseFloat(this.totalSkills / this.totalUsersWithSkills).toFixed(2)
    },
    skillsDescription() {
      return `Total: ${this.numberWithThousands(this.totalUsersWithSkills)}`
    },
  },
  methods: {
    numberWithThousands(x) {
      return x.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })
    },
    handleClickCreateProjectButton() {
      this.$router.push('/mis-proyectos/nuevo')
    },
  },
}
