//
//
//
//

import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { ScatterChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  TooltipComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  LabelLayout,
])

export default {
  name: 'ScatterChart',
  props: {
    chartId: {
      type: String,
      default: 'graphic',
    },
    option: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: '400px',
    },
  },
  data() {
    return {
      myChart: null,
      resizeObserver: null,
    }
  },
  watch: {
    option: {
      deep: true,
      handler(newOption) {
        if (this.myChart) {
          this.myChart.setOption(newOption)
        }
      },
    },
  },
  mounted() {
    const chartDom = document.getElementById(this.chartId)
    this.myChart = echarts.init(chartDom)
    window.addEventListener('resize', this.resize)
    this.resizeObserver = new ResizeObserver(() => {
      this.resize()
    })
    this.resizeObserver.observe(chartDom)
    this.myChart.setOption(this.option)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    if (this.myChart) {
      this.myChart.dispose()
    }
  },
  methods: {
    resize() {
      if (this.myChart) {
        this.myChart.resize()
      }
    },
  },
}
