//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tag from '@/components/Shared/Tag'
import PROJECT_CONSTANTS from '@/constants/projects'

export default {
  name: 'DetailHeader',
  components: {
    Tag,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    getTagType() {
      return PROJECT_CONSTANTS[this.status]
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
