//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import {
  FormInput,
  FormSelect,
  FormDatePicker,
  ValidWatcher,
} from '@/components/Form'
import FormSearchOccupation from '../Occupation/FormSearchOccupation.vue'

export default {
  name: 'FormExperienceLaboral',
  components: {
    FormInput,
    FormSelect,
    FormDatePicker,
    ValidationObserver,
    ValidWatcher,
    FormSearchOccupation,
  },
  props: {
    defaultExperience: {
      type: Object,
      default: () => {
        return {
          id: '',
          business_area: '',
          company: '',
          start_date_work: '',
          end_date_work: '',
          present: true,
          occupation: '',
          is_current_role: false,
        }
      },
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    try {
      this.$store.commit('loading', true)
      this.options.businessAreas = await this.$strapi['$business-areas'].find()
      const [
        currentRole,
      ] = await this.$repository.experienceWork.getWithOccupations({
        is_current_role: true,
      })
      this.currentRole = currentRole
      if (this.defaultExperience.id) {
        this.experience = this.defaultExperience
      }
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      endDateRules: '',
      options: {
        businessAreas: [],
      },
      select: {
        businessAreas: false,
      },
      currentRole: null,
      experience: {
        id: null,
        business_area: '',
        company: '',
        occupation: '',
        start_date_work: '',
        end_date_work: '',
        present: true,
        is_current_role: false,
        custom_occupation: '',
      },
      doneTypingInterval: 1500,
      typingTimer: 0,
      showAddCustomOccupation: false,
    }
  },
  computed: {
    isVisibleNotificationChangeCurrentRole() {
      return (
        this.currentRole &&
        this.experience.is_current_role &&
        this.currentRole.id !== this.experience.id
      )
    },
    currentRoleTitle() {
      return this.currentRole.occupation
        ? this.currentRole.occupation.title
        : this.currentRole.custom_occupation
    },
  },
  watch: {
    experience: {
      handler(val) {
        this.experience.end_date_work = val.present
          ? null
          : this.experience.end_date_work
      },
      deep: true,
    },
    defaultExperience(value) {
      if (value.id) {
        this.experience = { ...this.defaultExperience }
      }
    },
  },
  methods: {
    changeEndDateWork() {
      if (
        this.experience.end_date_work &&
        this.experience.start_date_work > this.experience.end_date_work
      ) {
        this.experience.present = true
        this.endDateRules = ''
      }
      this.handleChangeValue()
    },
    timeoutTyping(value) {
      clearTimeout(this.typingTimer)
      this.select.businessAreas = true
      this.typingTimer = setTimeout(() => {
        this.searchBusinessArea(value)
      }, this.doneTypingInterval)
    },
    async searchBusinessArea(name) {
      try {
        this.options.businessAreas = await this.$repository.businessArea.findAll(
          {
            ...this.filterDefault,
            name_contains: name,
            _sort: 'name',
          }
        )
      } catch (e) {
        this.$buefy.notification.open({
          message: 'Ha ocurrido un error inesperado',
          type: 'is-danger',
          autoclose: true,
          duration: 10000,
        })
      } finally {
        this.select.businessAreas = false
      }
    },
    onChecked(value) {
      this.endDateRules = value ? '' : 'required'
      this.handleChangeValue()
    },
    handleChangeValue() {
      this.$emit('change', this.experience)
    },
    onValidChanged(valid) {
      this.$emit('valid-changed', valid)
    },
    handleChangeIsCurrentRole(value) {
      if (value) {
        this.experience.present = value
      }
    },
    handleCustomOccupationMode(value) {
      this.experience.custom_occupation = ''
      this.showAddCustomOccupation = value
    },
  },
}
