//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CreateOccupation from '@/components/Projects/Modals/CreateOccupation.vue'
import LoadOccupation from './MatchPotential/LoadOccupation.vue'
import MatchAverage from './MatchPotential/MatchAverage.vue'
import MatchByAge from './MatchPotential/MatchByAge.vue'
import MatchByGender from './MatchPotential/MatchByGender.vue'
import MatchDistribution from './MatchPotential/MatchDistribution.vue'
import MatchMax from './MatchPotential/MatchMax.vue'
import MatchPotential from './MatchPotential/MatchPotential.vue'

export default {
  name: 'MatchPotentialTab',
  components: {
    MatchAverage,
    MatchByAge,
    MatchByGender,
    MatchDistribution,
    MatchMax,
    MatchPotential,
    LoadOccupation,
    CreateOccupation,
  },
  props: {
    projectId: {
      type: Number,
      default: 0,
      required: true,
    },
    projectOccupations: {
      type: Array,
      default: () => [],
    },
    occupationsOptions: {
      type: Array,
      default: () => [],
    },
    mainTargetOccupationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      targetOccupationId: this.mainTargetOccupationId,
      form: null,
      modalVisible: false,
      displayedOccupation: null,
      selectedOccupation: null,
    }
  },
  created() {
    this.selectedOccupation = this.projectOccupations?.find(
      (occ) => occ.id === this.mainTargetOccupationId
    )
    this.displayedOccupation = this.selectedOccupation
  },
  methods: {
    async handleSubmit(newOccupation) {
      const occupations = [
        ...new Set([
          ...this.projectOccupations.map(({ id }) => id),
          newOccupation.id,
        ]),
      ]
      await this.$repository.project.update(this.projectId, {
        occupations,
      })

      this.$emit('fetch-occupations')
      this.modalVisible = false
    },
    handleSelectOccupation(occupation) {
      this.selectedOccupation = occupation
      this.targetOccupationId = occupation.id
    },
  },
}
