//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MatchAverageIcon from 'assets/svg/match-average-icon.svg'
import handleErrorMixin from '@/mixins/handleErrorMixin'

export default {
  name: 'MatchAverage',
  components: {
    MatchAverageIcon,
  },
  mixins: [handleErrorMixin],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    occupationId: {
      type: Number,
      default: 0,
    },
  },
  async fetch() {
    await this.fetchData()
  },
  data() {
    return {
      data: {
        itemPercentage: 0,
      },
    }
  },
  watch: {
    occupationId: {
      handler() {
        this.fetchData()
      },
    },
    projectId: {
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.$repository.project.getMatchAverage(
          this.projectId,
          this.occupationId
        )
        this.data.itemPercentage = response.avg_match
      } catch (error) {
        this.handleError(
          error,
          this.$t('projects.detail.matchPotential.maxMatch.errorMessage')
        )
      }
    },
  },
}
