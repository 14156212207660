//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tag from '@/components/Shared/Tag'
import PROJECT_CONSTANTS from '@/constants/projects'
import { TYPES } from '@/constants/project-types'
import { mapGetters, mapActions } from 'vuex'
import { showErrorNotification } from '@/util/notifications'
import { getObjectives, getFieldByKey } from '@/util/project'
import ProjectEditModal from '../Modals/ProjectEditModal'
import ProjectDeleteModal from '../Modals/ProjectDeleteModal'

export default {
  name: 'ShowProjectDetail',
  components: {
    Tag,
    ProjectEditModal,
    ProjectDeleteModal,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      showEditModal: 'project/getShowEditModal',
      showDeleteModal: 'project/getShowDeleteModal',
      getTotalUsers: 'project/getTotalUsers',
    }),
    getObjective() {
      const objectives = getObjectives(this.$t('projects.new.steps.first'))
      if (this.project.objective === PROJECT_CONSTANTS.OTHERS) {
        return `${getFieldByKey(PROJECT_CONSTANTS.OTHERS, objectives).name}: ${
          this.project.objective_detail
        }`
      }
      return getFieldByKey(this.project.objective, objectives).name
    },
    type() {
      const types = {
        [TYPES.RESKILLING]: this.$t('projects.types.reskilling'),
        [TYPES.UPSKILLING]: this.$t('projects.types.upskilling'),
        [TYPES.SELFDIAGNOSIS]: this.$t('projects.types.selfdiagnosis'),
        [TYPES.MODELS]: this.$t('projects.types.models'),
      }
      return types[this.project.type] || ''
    },
    targetOccupationTitle() {
      const targetOccupation = this.project.occupations.find((occupation) => {
        return occupation.id === this.project.main_target_occupation_id
      })
      return targetOccupation ? targetOccupation.title : ''
    },
    targetOccupationLabel() {
      return this.project.type === TYPES.SELFDIAGNOSIS
        ? this.$t('projects.detail.summary.targetOccupationAlternative')
        : this.$t('projects.detail.summary.targetOccupation')
    },
    modelList() {
      const names = this.project.skill_packs?.map((model) => {
        return model.name
      })
      return names ? names.join(', ') : ''
    },
    locationsList() {
      const names = this.project.locations?.map((location) => {
        return location.name
      })
      return names ? names.join(', ') : ''
    },
    getTagType() {
      return PROJECT_CONSTANTS[this.project.status]
    },
    activeUsers() {
      return this.getTotalUsers === 1
        ? '1 usuario activo'
        : `${this.getTotalUsers} usuarios activos`
    },
    hasModels() {
      return this.project?.has_models ?? false
    },
  },
  methods: {
    ...mapActions({
      setShowEditModal: 'project/setShowEditModal',
      setShowDeleteModal: 'project/setShowDeleteModal',
    }),
    toggleEditModal() {
      this.setShowEditModal(!this.showEditModal)
      this.setShowDeleteModal(false)
    },
    toggleDeleteModal() {
      this.toggleEditModal()
      this.setShowDeleteModal(true)
    },
    onEdit(project) {
      this.$emit('edit', project)
    },
    async validateIfCanDeleteProject() {
      if (this.project.users.length > 0) {
        await this.$repository.user.removeUsersOfProject({
          usersIds: this.project.users.map((user) => user.id),
        })
      }
      await this.$repository.project.delete(this.project.id)
    },
    async deleteAndCloseModalAlertDelete() {
      this.$store.commit('loading', true)
      try {
        await this.validateIfCanDeleteProject()
        this.setShowDeleteModal(false)
      } catch (error) {
        showErrorNotification(this, 'Ocurrio un error al eliminar el proyecto')
      } finally {
        this.$store.commit('loading', false)
        this.$router.push('/mis-proyectos')
      }
    },
  },
}
