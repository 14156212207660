//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import ModalDeleteUser from '@/components/Projects/users//ModalDeleteUser.vue'
import ModalErrorUsers from '@/components/Projects/users/ModalErrorUsers.vue'
import { mapGetters } from 'vuex'
import ModalEditUser from './ModalEditUser.vue'

export default {
  name: 'ProjectUserList',
  components: {
    TableEmptyData,
    ModalDeleteUser,
    ModalEditUser,
    ModalErrorUsers,
  },
  filters: {
    fullNames(user) {
      return `${user?.names} ${user?.lastnames}`
    },
    showEmail(user) {
      return user?.email
    },
    showShortEmail(user) {
      return user?.email?.length > 20
        ? `${user?.email?.substring(0, 20)}...`
        : user?.email
    },
  },
  props: {
    users: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
    filter: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      isShowModalErrorUsers: false,
      textModalErrorUsers: '',
      currentPage: 1,
      checkedRows: [],
      isShowModalDeleteUsers: false,
      isShowModalEditUsers: false,
      userToDelete: {},
      userToEdit: {},
    }
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    filteredUsers() {
      if (this.filter === '') {
        return this.users
      }
      const filterLowerCase = this.filter.toLowerCase()

      return this.users.filter((user) => {
        return (
          user.names.toLowerCase().includes(filterLowerCase) ||
          user.lastnames.toLowerCase().includes(filterLowerCase) ||
          user.email.toLowerCase().includes(filterLowerCase)
        )
      })
    },
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page
      this.$emit('on-page-change', page)
    },
    toogleModalDeleteUsers() {
      this.isShowModalDeleteUsers = !this.isShowModalDeleteUsers
    },
    toogleModalEditUsers() {
      this.isShowModalEditUsers = !this.isShowModalEditUsers
    },
    toogleModalErrorUsers() {
      this.isShowModalErrorUsers = !this.isShowModalErrorUsers
    },
    editUserAction(user) {
      this.userToEdit = user
      this.isShowModalEditUsers = true
    },
    deleteUserAction(user) {
      this.userToDelete = user
      this.isShowModalDeleteUsers = true
    },
    async deleteUserConfirmed(userToDelete) {
      this.$store.commit('loading', true)
      const usersIds = this.users
        .filter((user) => user.id !== userToDelete.id)
        .map((user) => user.id)
      await this.$repository.project.update(this.getBaseProject.id, {
        users: usersIds,
        total_users: usersIds.length,
      })
      this.$emit('user-deleted')
    },
    isUserFromThisProject(user) {
      return user.original_project === this.getBaseProject.id
    },
    async editUserConfirmed(userToEdit) {
      this.$store.commit('loading', true)
      try {
        await this.$repository.user.update(userToEdit.id, userToEdit)
        this.$emit('user-edited')
        this.toogleModalEditUsers()
      } catch (e) {
        if (e.message === 'username.alreadyTaken.') {
          this.textModalErrorUsers = 'el email ya pertenece a otro usuario.'
        }
        this.toogleModalErrorUsers()
        this.$store.commit('loading', false)
      }
    },
  },
}
