//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FormInput } from '@/components/Form'
import ProgressIndicatorFilter from '@/components/Shared/ProgressIndicatorFilter'

export default {
  components: {
    FormInput,
    ProgressIndicatorFilter,
  },
  props: {
    filterMin: {
      type: Number,
      default: 0,
    },
    filterMax: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    text: '',
  }),
  methods: {
    handleInputText(value) {
      this.$emit('change-text', value)
    },
    resetText() {
      this.text = ''
      this.$emit('reset-text', '')
    },
    handleChangeMatch(value) {
      this.$emit('change-range', value)
    },
  },
}
