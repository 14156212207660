//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ShowProjectDetail from '@/components/Projects/OldsReports/Detail/Tabs/ShowProjectDetail'
import ProjectServicesDetail from '@/components/Projects/OldsReports/Detail/Tabs/ProjectServicesDetail.vue'

export default {
  name: 'ProjectDetailTab',
  components: {
    ShowProjectDetail,
    ProjectServicesDetail,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
    showServiceDetail: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onEdit(project) {
      this.$emit('edit', project)
    },
  },
}
