//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkillsInventoryCharts from '@/components/SkillsInventory/Charts/SkillsInventoryCharts'
import WorkerSkillsSection from '@/components/SkillsInventory/WorkerSkillsSection'

export default {
  name: 'WorkerSkillsInventory',
  components: {
    SkillsInventoryCharts,
    WorkerSkillsSection,
  },
  props: {
    userId: {
      type: Number,
      default: () => 0,
    },
    visibleCharts: {
      type: Boolean,
      default: () => true,
    },
    byTypeTitle: {
      type: String,
      default: '',
    },
    byTypeDescription: {
      type: String,
      default: '',
    },
    byReuseLevelTitle: {
      type: String,
      default: '',
    },
    byReuseLevelDescription: {
      type: String,
      default: '',
    },
    defaultSkillMorphs: {
      type: Array,
      default: null,
    },
  },
  async fetch() {
    this.skillMorphs = !this.defaultSkillMorphs
      ? await this.getSkillMorphsByUser()
      : this.defaultSkillMorphs
  },
  data() {
    return {
      skillMorphs: [],
    }
  },
  methods: {
    getSkillMorphsByUser() {
      return this.$repository.skillMorph.findAllWithSkills({
        has_skill: true,
        user: this.userId,
        _limit: -1,
      })
    },
  },
}
