//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScatterChart from '@/components/DeliveryResults/Graphics/ScatterChart.vue'
import GENDERS from '@/constants/genders'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import MatchDistributioHeader from './MatchDistributioHeader.vue'
import ChartLegend from '~/components/DeliveryResults/Graphics/ChartLegend.vue'

export default {
  name: 'MatchDistribution',
  components: { ScatterChart, ChartLegend, MatchDistributioHeader },
  mixins: [handleErrorMixin],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    occupationId: {
      type: Number,
      default: 0,
    },
  },
  async fetch() {
    await this.fetchData()
  },
  data() {
    return {
      legend: {
        elements: [
          {
            id: GENDERS.MALE,
            label: 'Masculino',
            color: '#F11E64',
            isActive: true,
            typeIcon: 'bar',
          },
          {
            id: GENDERS.FEMALE,
            label: 'Femenino',
            color: '#2CD5C4',
            isActive: true,
            typeIcon: 'bar',
          },
          {
            id: GENDERS.OTHERS,
            label: 'Otros',
            color: '#16222D',
            isActive: true,
            typeIcon: 'bar',
          },
          {
            id: GENDERS.NOT_SPECIFIED,
            label: 'Prefiero no decir',
            color: '#7A7A7A',
            isActive: true,
            typeIcon: 'bar',
          },
        ],
        secondaryElements: [
          {
            id: 'MATCH',
            label: 'Corte de match',
            color: '#F11E64',
            isActive: true,
            typeIcon: 'line-dashed',
          },
        ],
      },
      baseOptions: {
        grid: {
          left: 25,
          right: 16,
          bottom: 24,
          top: 20,
          containLabel: true,
        },
        tooltip: {
          showDelay: 0,
          formatter(params) {
            if (params.value.length > 3) {
              return `<strong>${params.value[2]}</strong><br/><strong>${params.value[3]}</strong><br/>Match: ${params.value[0]}%<br/>Habilidades: ${params.value[1]}`
            } else {
              return 'Sin datos'
            }
          },
          axisPointer: {
            show: false,
          },
        },
        toolbox: {
          show: true,
          itemSize: 14,
          top: -5,
          right: 9,
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: 'Zoom',
                back: 'Restaurar zoom',
              },
            },
            brush: {
              type: [],
            },
          },
        },
        xAxis: [
          {
            type: 'value',
            splitNumber: 10,
            scale: true,
            name: 'Porcentaje de Match',
            nameLocation: 'middle',
            axisLabel: {
              formatter: '{value}%',
            },
            nameTextStyle: {
              fontSize: 10,
              padding: [20, 8, 8, 8],
              color: '#7A7A7A',
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
            min: 0,
            max: 100,
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 10,
            name: 'Nº de habilidade ingresadas',
            nameRotate: 90,
            nameLocation: 'middle',
            axisLabel: {
              margin: 10,
              show: true,
              rotate: true,
            },
            nameTextStyle: {
              fontSize: 10,
              padding: [10, 10, 17, 10],
              color: '#7A7A7A',
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          },
        ],
        series: [],
      },
      series: [
        {
          name: GENDERS.FEMALE,
          color: '#2CD5C4',
          type: 'scatter',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: GENDERS.MALE,
          color: '#F11E64',
          type: 'scatter',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: GENDERS.OTHERS,
          type: 'scatter',
          color: '#16222D',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: GENDERS.NOT_SPECIFIED,
          type: 'scatter',
          color: '#7A7A7A',
          emphasis: {
            focus: 'series',
          },
          data: [],
        },
        {
          name: 'MATCH',
          type: 'scatter',
          emphasis: {
            focus: 'series',
          },
          color: '#F11E64',
          markLine: {
            symbol: 'none',
            lineStyle: {
              type: 'dashed',
            },
            tooltip: {
              show: false,
            },
            label: {
              color: '#F11E64',
              formatter: '{c}%',
              fontSize: 10,
            },
            data: [{ xAxis: 75 }],
          },
        },
      ],
    }
  },
  computed: {
    activeItems() {
      return [...this.legend.elements, ...this.legend.secondaryElements]
        .filter((element) => element.isActive)
        .map((element) => element.id)
    },
    options() {
      return this.getOptions()
    },
    isEmpty() {
      return this.series
        .filter(
          ({ name }) => name !== 'MATCH' && this.activeItems.includes(name)
        )
        .every((serie) => serie.data.length === 0)
    },
  },
  watch: {
    occupationId: {
      handler() {
        this.fetchData()
      },
    },
    projectId: {
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        this.$store.commit('loading', true)
        const matchDetails = await this.$repository.project.getMatchDetail(
          this.projectId,
          this.occupationId
        )
        this.series = this.getSeriesOfMatchDistribution(matchDetails)
      } catch (error) {
        this.handleError(error, this.$t('skillsInventory.error.skillStats'))
      } finally {
        this.$store.commit('loading', false)
      }
    },
    handleChangeElements(elements) {
      this.legend.elements = elements
    },
    handleChangeSecondaryElements(elements) {
      this.legend.secondaryElements = elements
    },
    getOptions() {
      return {
        ...this.baseOptions,
        series: this.series.map((serie) => {
          if (serie.name === 'MATCH') {
            return {
              ...serie,
              markLine: {
                ...serie.markLine,
                data: this.activeItems.includes(serie.name)
                  ? [{ xAxis: 75 }]
                  : [],
              },
            }
          }
          return {
            ...serie,
            data: this.activeItems.includes(serie.name) ? serie.data : [],
          }
        }),
      }
    },
    getSeriesOfMatchDistribution(matchs) {
      const matchByGender = Object.groupBy(matchs, (match) => match.gender)
      return this.series.map((serie) => ({
        ...serie,
        data: this.formatData(matchByGender[serie.name] || []),
      }))
    },
    formatData(data) {
      return data.map((item) => [
        Number(item.match_value),
        Number(item.skills),
        item.names,
        item.lastnames,
      ])
    },
  },
}
