//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ROLES from '@/constants/roles'
import UserInvitationList from '@/components/Projects/Detail/Tabs/UserInvitationList.vue'
import MessageWithoutHeader from '@/components/Shared/MessageWithoutHeader.vue'
import Button from '@/components/Shared/Button'
import FilterFormGroup from '@/components/SkillsInventory/Filters/FilterFormGroup'
import FilteredTag from '@/components/SkillsInventory/FilteredTag'
import ModalAddUsers from '@/components/Projects/users/ModalAddUsers.vue'
import ModalErrorUsers from '@/components/Projects/users/ModalErrorUsers.vue'
import ModalUsersWithoutEmail from '@/components/Projects/users/ModalUsersWithoutEmail.vue'
import ModalResendMassive from '@/components/Projects/users/ModalResendMassive.vue'
import { mapActions, mapState } from 'vuex'
import ConfirmRegisterUserWithEmailModal from '~/components/Invitation/ConfirmRegisterUserWithEmailModal.vue'
import MassiveImportUserModal from '~/components/Projects/users/MassiveImportUserModal.vue'
import MassiveImportInfoUserModal from '~/components/Projects/users/MassiveImportInfoUserModal.vue'
import ConfirmRegisterMassiveUser from '~/components/Invitation/ConfirmRegisterMassiveUser.vue'
import { INVITATION_TO_PROJECT } from '~/constants/reminder-email-templates'

export default {
  name: 'ProjectDetailTab',
  components: {
    ModalAddUsers,
    MassiveImportUserModal,
    ModalErrorUsers,
    ModalUsersWithoutEmail,
    MessageWithoutHeader,
    ModalResendMassive,
    ConfirmRegisterUserWithEmailModal,
    ConfirmRegisterMassiveUser,
    UserInvitationList,
    MassiveImportInfoUserModal,
    Button,
    FilterFormGroup,
    FilteredTag,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    await this.getEmployeesInProject()
    await this.getEmployees()
  },
  data: () => ({
    modalActive: false,
    modalMassiveResendActive: false,
    modalInvitationActive: false,
    modalConfirmRegisterUserWithoutEmail: false,
    modalConfirmRegisterUserWithEmail: false,
    modalMassiveImportUser: false,
    modalInfoMassiveImportUser: false,
    modalConfirmRegisterMassiveUser: false,
    isShowModalAddUsers: false,
    isShowModalMassiveImportUser: false,
    isShowModalInfoMassiveImportUser: false,
    isShowModalErrorUsers: false,
    isShowModalUsersWithoutEmail: false,
    isShowModalResendMassive: false,
    usersInfo: [],
    employees: [],
    textModalErrorUsers: '',
    table: {
      filters: {
        _sort: 'created_at:DESC',
        populate: ['user.role'],
        _where: {},
        _limit: 10,
        _start: 0,
      },
      filtersPending: {
        _where: { active: true },
      },
      data: [],
      total: 0,
      totalPending: 0,
    },
    showFilters: false,
    filters: {
      names: '',
      email: [],
      progress: [],
      invitation: [],
    },
    selectedFilters: {
      email: [],
      progress: [],
      invitation: [],
    },
    options: {
      status: [
        {
          label: 'Aceptada',
          key: false,
        },
        {
          label: 'Pendiente',
          key: true,
        },
      ],
    },
    emailTemplate: INVITATION_TO_PROJECT,
  }),
  computed: {
    ...mapState({
      employeesInProject: (state) => state.project.employeesInProject,
    }),
    defaultFilter() {
      return {
        'user.role.type': ROLES.EMPLOYEE,
        'user.company': this.$store.state.me.user.company?.id,
      }
    },
    rolTypeToResend() {
      return ROLES.EMPLOYEE
    },
    isDisabled() {
      if (this.$store.getters['emails/resendEmails'].length > 0) {
        return false
      }
      return true
    },
    showFiltersTag() {
      return (
        this.selectedFilters.email.length > 0 ||
        this.selectedFilters.invitation.length > 0
      )
    },
    calculateDataWithFilters() {
      const data = this.employeesInProject.filter((item) => {
        let toAdd = true
        if (this.filters.names !== '') {
          const name = item.names.toLowerCase()
          const lastName = item.lastnames.toLowerCase()
          const username = item.username.toLowerCase()
          const filter = this.filters.names.toLowerCase()
          if (
            !name.includes(filter) &&
            !lastName.includes(filter) &&
            !username.includes(filter)
          ) {
            toAdd = false
          }
        }
        if (this.selectedFilters.email.length > 0) {
          // Iterate over each email filter
          this.selectedFilters.email.forEach((emailFilter) => {
            if (!emailFilter.value && !item.autogenerated_email) {
              toAdd = false
            } else if (emailFilter.value && item.autogenerated_email) {
              toAdd = false
            }
          })
        }
        if (this.selectedFilters.invitation.length > 0) {
          // Iterate over each invitation filter
          this.selectedFilters.invitation.forEach((invitationFilter) => {
            if (!invitationFilter.value && !item.show_change_password_modal) {
              toAdd = false
            } else if (
              invitationFilter.value &&
              item.show_change_password_modal
            ) {
              toAdd = false
            }
          })
        }
        return toAdd
      })

      const total = data.length
      const totalPending = data.filter(
        (employee) =>
          employee.show_change_password_modal === true &&
          !employee.autogenerated_email
      ).length

      return { data, total, totalPending }
    },
  },
  created() {
    this.table.filters._where = this.defaultFilter
    this.table.filtersPending._where = {
      ...this.table.filtersPending._where,
      ...this.defaultFilter,
    }
  },
  methods: {
    ...mapActions({
      getEmployeesInProjectAction: 'project/getEmployeesInProject',
    }),
    async successInvitation() {
      await this.$fetch()
    },
    onSearch() {
      this.selectedFilters.email = this.filters.email
      this.selectedFilters.invitation = this.filters.invitation
      this.onShowFilters()
    },
    onShowFilters() {
      this.showFilters = !this.showFilters
    },
    onCloseEmailTag() {
      this.filters.email = []
      this.selectedFilters.email = []
    },
    onCloseInvitationTag() {
      this.filters.invitation = []
      this.selectedFilters.invitation = []
    },
    toggleModal() {
      this.modalActive = !this.modalActive
    },
    toggleModalInvitation() {
      this.modalInvitationActive = !this.modalInvitationActive
    },
    toggleModalConfirmRegisterUserWithoutEmail() {
      this.modalConfirmRegisterUserWithoutEmail = !this
        .modalConfirmRegisterUserWithoutEmail
    },
    toggleModalConfirmRegisterUserWithEmail() {
      this.modalConfirmRegisterUserWithEmail = !this
        .modalConfirmRegisterUserWithEmail
    },
    toggleMasiveResendModal() {
      this.modalMassiveResendActive = !this.modalMassiveResendActive
    },
    toggleMassiveImportModal() {
      this.isShowModalMassiveImportUser = !this.isShowModalMassiveImportUser
    },
    toggleMassiveInfoImportModal() {
      this.isShowModalInfoMassiveImportUser = !this
        .isShowModalInfoMassiveImportUser
    },
    async finishSavedUserWithoutEmail() {
      this.toggleModalConfirmRegisterUserWithoutEmail()
      await this.$fetch()
    },
    async finishSavedUserWithEmail() {
      this.toggleModalConfirmRegisterUserWithEmail()
      await this.$fetch()
    },
    toggleMassiveResend() {
      this.isShowModalResendMassive = !this.isShowModalResendMassive
    },
    async getEmployees() {
      this.employees = await this.$repository.user.findPopulate({
        _where: {
          company: this.$strapi.user.company.id,
          'role.type': ROLES.EMPLOYEE,
        },
        _limit: -1,
        populate: [],
      })
    },
    async getEmployeesInProject() {
      this.$store.commit('loading', true)
      await this.getEmployeesInProjectAction(this.$route.params.id)
      this.$store.commit('loading', false)
    },
    onPageChange(page) {
      this.table.filters._start =
        this.table.filters._limit * page - this.table.filters._limit
      this.$fetch()
    },
    toogleModalAddUsers() {
      this.isShowModalAddUsers = !this.isShowModalAddUsers
    },
    handleMassiveModel() {
      this.toogleModalAddUsers()
      this.toggleMassiveImportModal()
    },
    sendUsersDetails(users) {
      this.usersInfo = users
      this.toggleMassiveImportModal()
      this.toggleMassiveInfoImportModal()
    },
    async toggleModalConfirmRegisterMassiveUser() {
      this.toggleMassiveInfoImportModal()
      this.modalConfirmRegisterMassiveUser = !this
        .modalConfirmRegisterMassiveUser
      await this.$fetch()
      this.$store.commit('loading', false)
    },
    async toggleModalConfirmResendMassiveUser() {
      this.toggleMassiveResend()
      this.$store.commit('emails/setResendAll', false)
      this.$store.commit('emails/setResendEmails', [])
      this.modalConfirmRegisterMassiveUser = !this
        .modalConfirmRegisterMassiveUser
      await this.$fetch()
      this.$store.commit('loading', false)
    },
    handleUserWithError(message) {
      this.textModalErrorUsers = message
      this.isShowModalErrorUsers = true
    },
    toogleModalErrorUsers() {
      this.isShowModalErrorUsers = !this.isShowModalErrorUsers
    },
    handleUserWithoutEmailConfirm() {
      this.isShowModalUsersWithoutEmail = true
    },
    toogleModalUsersWithoutEmail() {
      this.isShowModalUsersWithoutEmail = !this.isShowModalUsersWithoutEmail
    },
    handleCreateUserWithoutEmail() {
      this.$refs.modalAddUsers.handleCreateUserWithoutEmail()
    },
    async finishSaveUser() {
      this.isShowModalAddUsers = !this.isShowModalAddUsers
      this.toggleModalConfirmRegisterUserWithEmail()
      await this.$fetch()
      this.$store.commit('loading', false)
    },
    async updateAfterDelete() {
      await this.$fetch()
    },
  },
}
