const Request = require('@/repositories/request')
const qs = require('qs')
class Me extends Request {
  getMe() {
    return this.$strapi.user
  }

  async getMyCompany(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return await this.$strapi.$http.get(`/me/company`, stringifyParams)
  }

  /**
   *
   * @param {int} opportunityId
   * @returns {Promise<*>}
   */
  async downloadReportOpportunityById(opportunityId) {
    const file = await this.$strapi.$http.get(
      `/me/opportunity/${opportunityId}/pdf`
    )
    return file.blob()
  }

  /**
   *
   * @returns {Promise<*>}
   */
  async downloadReportExcelOfCompany() {
    const file = await this.$strapi.$http.get('/me/companies/report/excel')
    return file.blob()
  }

  /**
   *
   * @returns {Promise<*>}
   */
  async downloadReportExcelOfCompanyOverview() {
    const file = await this.$strapi.$http.get(
      '/me/companies/report/excel-overview'
    )
    return file.blob()
  }

  async downloadReportExcelOfSkillsToBeWorked() {
    const file = await this.$strapi.$http.get(
      '/me/companies/report/excel-skills-to-be-worked'
    )
    return file.blob()
  }

  async downloadReportExcelExperiences() {
    const file = await this.$strapi.$http.get(
      '/me/companies/report/excel-experiences'
    )
    return file.blob()
  }

  async downloadReportExcelInventorySkills() {
    const file = await this.$strapi.$http.get(
      '/me/companies/report/excel-inventory-skills'
    )
    return file.blob()
  }

  /**
   *
   * @returns {Promise<*>}
   */
  async downloadReportMatchAndCourses() {
    const file = await this.$strapi.$http.get(
      '/me/companies/report/match-courses'
    )
    return file.blob()
  }

  /**
   *
   * @returns {Promise<*>}
   */
  async downloadReportExcelOfJobSeekerFair() {
    const file = await this.$strapi.$http.get(
      '/me/job-seeker-fairs/report/excel'
    )
    return file.blob()
  }

  /**
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getCompanyOpportunitiy(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(
      `/me/companies/opportunities?${stringifyParams}`
    )
  }

  /**
   *
   * @param {int} occupationId
   * @returns {Promise<unknown>}
   */
  // TODO this method is non used
  getPotentialWorkerByOccupationId(occupationId) {
    return this.$strapi.$http.$get(
      `me/companies/potential-workers-by-occupation/${occupationId}`
    )
  }

  updateCompanyConfigs(companyConfigs) {
    return this.$strapi.$http.$put(`me/company-configs/`, { companyConfigs })
  }

  /**
   *
   * @param {string} data.names
   * @param {string} data.lastnames
   * @param {string} data.phone
   * @param {string} data.birthdate
   * @param {string} data.address
   * @param {boolean} data.policy_data
   * @returns {Promise<unknown>}
   */
  update(data) {
    return this.$strapi.$http.$put(`/me`, data)
  }

  /**
   *
   * @return {Promise<unknown>}
   */
  findUserOfCompany() {
    return this.$strapi.$http.$get('/me/companies/potential-workers')
  }

  /**
   *
   * @param {int} occupationId
   * @return {Promise<unknown>}
   */
  findUserOfCompanyFilteredOccupation(occupationId) {
    return this.$strapi.$http.$get(
      `/me/companies/potential-workers-by-occupation/${occupationId}`
    )
  }

  fetchUser() {
    return this.$strapi.fetchUser()
  }

  /**
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getJobSeekerFairOpportunitiy(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(
      `/me/job-seeker-fairs/opportunities?${stringifyParams}`
    )
  }

  /**
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  potentialWorkerByJobSeekerFairByOccupation(occupationId) {
    return this.$strapi.$http.$get(
      `/me/job-seeker-fairs/potential-workers/occupation/${occupationId}`
    )
  }

  /**
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getTrainningOpportunities(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(
      `/me/opportunities-trainning?${stringifyParams}`
    )
  }

  /**
   *
   * @param integration
   * @returns {Promise<unknown>}
   */
  getTest(integration) {
    return this.$strapi.$http.$get(`/me/tests/${integration}`)
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getExperienceWork() {
    return this.$strapi.$http.$get('/me/experience-works')
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getExperienceEducational() {
    return this.$strapi.$http.$get('/me/experience-educationals')
  }
}

module.exports = Me
