//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TitleDescription from '@/components/Shared/TitleDescription'
import MatchPotentialTable from './MatchPotentialTable.vue'
import MatchPotentialSearchBar from './MatchPotentialSearchBar.vue'
import { showErrorNotification } from '~/util/notifications'

export default {
  components: {
    MatchPotentialTable,
    MatchPotentialSearchBar,
    TitleDescription,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    occupationId: {
      type: Number,
      required: true,
    },
  },
  async fetch() {
    await this.getMatchByOccupaton()
  },
  data: () => ({
    matchs: {},
    filters: {
      email: '',
    },
    betweenMin: 0,
    betweenMax: 100,
    between: {
      match: [0, 100],
    },
    search: false,
    page: 1,
    perPage: 10,
    total: 0,
    sort: {
      field: 'match',
      order: 'DESC',
    },
  }),
  watch: {
    occupationId: {
      handler() {
        this.getMatchByOccupaton()
      },
    },
    projectId: {
      handler() {
        this.getMatchByOccupaton()
      },
    },
  },
  methods: {
    async onReset() {
      this.page = 1
      this.perPage = 10
      this.total = 0
      this.filters.email = ''
      this.between = {
        match: [this.betweenMin, this.betweenMax],
      }
      this.search = false
      await this.getMatchByOccupaton()
    },
    async getMatchByOccupaton() {
      this.$store.commit('loading', true)
      try {
        this.matchs = await this.$repository.project.getMatchUsersByProjectAndOccupations(
          this.projectId,
          {
            occupationId: this.occupationId,
            sort_field: this.sort.field,
            sort_order: this.sort.order,
            per_page: this.perPage,
            page: this.page,
            search: this.search ? this.filters : undefined,
            between: this.between,
          }
        )
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error')
      } finally {
        this.$store.commit('loading', false)
      }
    },
    handleChangeFilterText(text) {
      this.filters.email = text
      if (text.length >= 3) {
        this.search = true
        this.getMatchByOccupaton()
      }
    },
    handleChangeMatch(value) {
      this.between.match = [value.min, value.max]
      this.getMatchByOccupaton()
    },
    async onPageChange(page) {
      this.page = page
      await this.getMatchByOccupaton()
    },
    async onSort({ field, order }) {
      this.sort = { field, order }
      await this.getMatchByOccupaton()
    },
  },
}
