import { render, staticRenderFns } from "./FormExperienceLaboral.vue?vue&type=template&id=45d43c51&scoped=true&"
import script from "./FormExperienceLaboral.vue?vue&type=script&lang=js&"
export * from "./FormExperienceLaboral.vue?vue&type=script&lang=js&"
import style0 from "./FormExperienceLaboral.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./FormExperienceLaboral.vue?vue&type=style&index=1&id=45d43c51&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d43c51",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default,FormSelect: require('/workspace/components/Form/FormSelect.vue').default,FormDatePicker: require('/workspace/components/Form/FormDatePicker.vue').default})
