//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadOccupationModal from '@/components/Projects/Detail/Tabs/MatchPotential/LoadOccupationModal.vue'
import Button from '@/components/Shared/Button'

export default {
  name: 'MatchPotentialTab',
  components: {
    LoadOccupationModal,
    Button,
  },
  props: {
    /**
     * The project id
     */
    projectId: {
      type: Number,
      default: 0,
    },
    /**
     * The project occupations
     */
    projectOccupations: {
      type: Array,
      default: () => [],
    },
    /**
     * All occupations that can be added to the project
     */
    occupationsOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * Occupation shown in input
     */
    selectedOccupation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: null,
      modalVisible: false,
      displayedOccupation: null,
    }
  },
  watch: {
    displayedOccupation(newOccupation, oldOccupation) {
      if (!oldOccupation) {
        return
      }
      this.$emit('select-occupation', newOccupation)
    },
  },
  mounted() {
    this.displayedOccupation = this.selectedOccupation
  },
  methods: {
    async handleSubmit(newOccupation) {
      const occupations = [
        ...new Set([
          ...this.projectOccupations.map(({ id }) => id),
          newOccupation.id,
        ]),
      ]
      await this.$repository.project.update(this.projectId, {
        occupations,
      })

      this.$emit('fetch-occupations')
      this.modalVisible = false
    },
    selectOccupation(occupation) {
      this.displayedOccupation = occupation
    },
  },
}
