//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartLegendItem from './ChartLegendItem.vue'
export default {
  components: {
    ChartLegendItem,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    secondaryElements: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({}),
  methods: {
    handleClickElement(id) {
      const newElements = [...this.elements]
      const elementIndex = newElements.findIndex((element) => element.id === id)
      newElements[elementIndex].isActive = !newElements[elementIndex].isActive
      this.$emit('change-elements', newElements)
    },
    handleClickSecondaryElement(id) {
      const newSecondaryElements = [...this.secondaryElements]
      const elementIndex = newSecondaryElements.findIndex(
        (element) => element.id === id
      )
      newSecondaryElements[elementIndex].isActive = !newSecondaryElements[
        elementIndex
      ].isActive
      this.$emit('change-secondary-elements', newSecondaryElements)
    },
  },
}
