import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import config from '~/config'

export default (ctx) => {
  if (!config.enabledSentry) {
    Sentry.init({
      Vue,
      dsn: process.env.sentryDsn,
      integrations: [
        Sentry.browserTracingIntegration({ router: ctx.app.router }),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      environment: process.env.sentryEnvironment,
      tracesSampleRate: 0.1,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/relink\.com/,
        /^https:\/\/www\.relink\.com/,
        /^https:\/\/app\.relink\.com/,
        /^https:\/\/staging\.relink\.com/,
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      profilesSampleRate: 1.0,
    })
  }
}
