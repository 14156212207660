//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import PROJECT from '@/constants/projects'
import moment from 'moment'

export default {
  components: {
    Button,
  },
  data: () => ({
    text: '',
    status: null,
    isVisibleReportOptions: false,
  }),
  computed: {
    statusOptions() {
      return [
        {
          value: PROJECT.DRAFT.value,
          text: PROJECT.DRAFT.name,
        },
        {
          value: PROJECT.ACTIVE.value,
          text: PROJECT.ACTIVE.name,
        },
        {
          value: PROJECT.FINISHED.value,
          text: PROJECT.FINISHED.name,
        },
      ]
    },
    today() {
      return moment().format('DD_MM_YYYY')
    },
  },
  methods: {
    handleInputText(value) {
      this.$emit('change-text', value)
    },
    handleSelectStatus(selectOption) {
      const value = selectOption === null ? null : selectOption.value
      this.$emit('change-status', value)
    },
    handleClickCreateProjectButton() {
      this.$router.push('/mis-proyectos/nuevo')
    },
    changeReportDropdown(active) {
      this.isVisibleReportOptions = active
    },
    async downloadReportEmployeeData() {
      this.$store.commit('loading', true)
      try {
        const file = await this.$repository.company.downloadReportDataEmployee()
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(file)
        link.download = `Datos-trabajadores(empresa).xlsx`
        link.click()
      } catch (e) {
        this.$buefy.notification.open({
          message: 'Se ha encontrado un error en el sistema.',
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
    async downloadReportExecutive() {
      this.$store.commit('loading', true)
      try {
        const file = await this.$repository.company.downloadReportExecutive()
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(file)
        const companyName = this.$strapi.user.company.name
        link.download = `${this.$t(
          'projects.detail.reports.executive'
        )} - ${companyName} - ${this.today}.xlsx`
        link.click()
      } catch (e) {
        this.$buefy.notification.open({
          message: 'Se ha encontrado un error en el sistema.',
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
    resetText() {
      this.text = ''
    },
  },
}
