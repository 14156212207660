//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'is-primary',
    },
    label: {
      type: String,
      default: '',
    },
    defaultMin: {
      type: Number,
      default: 20,
    },
    defaultMax: {
      type: Number,
      default: 70,
    },
  },
  data() {
    return {
      min: this.defaultMin,
      max: this.defaultMax,
      draggingHandle: null,
    }
  },
  computed: {
    rangeStyle() {
      return {
        left: `${this.min}%`,
        width: `${this.max - this.min}%`,
      }
    },
    minHandleStyle() {
      return {
        left: `${this.min}%`,
      }
    },
    maxHandleStyle() {
      return {
        left: `${this.max}%`,
      }
    },
  },
  methods: {
    setDragging(handle, event) {
      event.preventDefault()
      this.draggingHandle = handle
    },
    startDragging(event) {
      event.preventDefault()
      this.dragging(event)
      document.addEventListener('mousemove', this.dragging)
      document.addEventListener('mouseup', this.stopDragging)
      document.addEventListener('touchmove', this.dragging)
      document.addEventListener('touchend', this.stopDragging)
    },
    dragging(event) {
      if (!this.draggingHandle) return

      // Detecta si es evento táctil o de mouse
      const clientX = event.touches ? event.touches[0].clientX : event.clientX

      const progressBar = this.$refs.progressBar
      const rect = progressBar.getBoundingClientRect()
      const x = clientX - rect.left
      const percentage = (x / rect.width) * 100

      if (this.draggingHandle === 'min') {
        this.min = Math.round(Math.min(Math.max(0, percentage), this.max - 5))
      } else if (this.draggingHandle === 'max') {
        this.max = Math.round(Math.max(Math.min(100, percentage), this.min + 5))
      }
    },
    stopDragging() {
      document.removeEventListener('mousemove', this.dragging)
      document.removeEventListener('mouseup', this.stopDragging)
      document.removeEventListener('touchmove', this.dragging)
      document.removeEventListener('touchend', this.stopDragging)
      this.draggingHandle = null
      this.$emit('change', { min: this.min, max: this.max })
    },
  },
}
