const Request = require('@/repositories/request')
const qs = require('qs')
const { encodeQueryParams } = require('~/util/query')

class Company extends Request {
  /**
   *
   * @param {{_where: Object,
        _limit: int,
        _start: int}} params
   * @returns {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$companies.find(stringifyParams)
  }

  findAllBasicInfo(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$http.$get(`/companies/basic-info?${stringifyParams}`)
  }

  findById(id, params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$companies.findOne(id, stringifyParams)
  }

  findLevel() {
    return this.$strapi.$http.$get(`/companies/levels`)
  }

  /**
   *
   * @param {{_where: Object,
      _limit: int,
      _start: int}} params
   * @returns {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$companies.count(stringifyParams)
  }

  /**
   *
   * @param params
   * @returns {*}
   */
  create(params) {
    return this.$strapi.$companies.create(params)
  }

  update(id, data) {
    return this.$strapi.$companies.update(id, data)
  }

  /**
   *
   * @param params
   * @returns {Promise<{totalDemoCompanies: int, demoCompanies:[]}>}
   */
  getDemoCompanies(params) {
    const query = qs.stringify({ ...params })
    return this.$strapi.$http.$get(`/companies/demo-companies?${query}`)
  }

  /**
   *
   * @param {int} id
   * @param {boolean} isBlocked
   * @returns {Promise<unknown>}
   */
  blockedUserByCompanyId(isBlocked) {
    return this.$strapi.$http.$put(`/companies/users/blocked`, {
      blocked: isBlocked,
    })
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  countPilotCompanies() {
    return this.$strapi.$http.$get(`/companies/pilot/count`)
  }

  countPilotCompaniesFromDate(dateStart) {
    return this.$strapi.$http.$get(
      `/companies/pilot/count?date_start=${dateStart}`
    )
  }

  /**
   *
   * @param {int} companyId
   * @return {Promise<unknown>}
   */
  getUsersByCompanyId() {
    return this.$strapi.$http.$get(`/companies/users`)
  }

  getEmployeesByCompanyId() {
    return this.$strapi.$http.$get(`/companies/employees`)
  }

  /**
   *
   * @returns {*}
   * @param page
   */
  getTotalOccupationsEmployeesByCompany(page) {
    return this.$strapi.$http.$get(
      `/companies/total-occupation-employees-by-company?page=${page}`
    )
  }

  /**
   *
   * @returns {*}
   * @param page
   */
  getTotalPendingInvitationsByCompany(page) {
    return this.$strapi.$http.$get(
      `/companies/total-pending-invitations-by-company?page=${page}`
    )
  }

  /**
   *
   * @returns {*}
   * @param page
   */
  getPilotCompanysForAdminRelik(params) {
    const query = qs.stringify({ ...params })
    return this.$strapi.$http.$get(
      `/companies/pilot-companies-relink-admin?${query}`
    )
  }

  /**
   *
   * @returns {int} total
   */
  getTotalPilotCompanysForAdminRelik() {
    return this.$strapi.$http.$get(
      `/companies/pilot-companies-relink-admin/count`
    )
  }

  getAllWorkersByCompany() {
    const companyId = this.$strapi.user.company.id
    return this.$strapi.$http.get(`/companies/${companyId}/users`)
  }

  previewReminderMail(params) {
    return this.$strapi.$http.get(
      `/companies/reminder/preview?${encodeQueryParams(params)}`
    )
  }

  getEmployeesWithExperiencesCount() {
    return this.$strapi.$http.$get(`/companies/employees-experiences-count`)
  }

  async downloadReportDataEmployee() {
    const file = await this.$strapi.$http.get(
      '/companies/report/employee-data-report'
    )
    return file.blob()
  }

  async downloadReportExecutive() {
    const file = await this.$strapi.$http.get(
      '/companies/report/executive-report'
    )
    return file.blob()
  }

  async downloadReportInventorySkills() {
    const file = await this.$strapi.$http.get(
      '/companies/report/skill-inventory-report'
    )
    return file.blob()
  }

  async inviteCompany(body) {
    return await this.$strapi.$http.$post('companies/invitation', body)
  }
}

module.exports = Company
