import { render, staticRenderFns } from "./InvitationListMassiveResend.vue?vue&type=template&id=6fce07ab&scoped=true&"
import script from "./InvitationListMassiveResend.vue?vue&type=script&lang=js&"
export * from "./InvitationListMassiveResend.vue?vue&type=script&lang=js&"
import style0 from "./InvitationListMassiveResend.vue?vue&type=style&index=0&id=6fce07ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fce07ab",
  null
  
)

export default component.exports