//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'

export default {
  components: {
    TableEmptyData,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    isEnabledPotentialMatch: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      selectedModel: 'all',
      paginated: false,
      perPage: 10,
    }
  },
  computed: {
    uniqueModels() {
      return [
        ...new Set(
          this.tableData.flatMap((item) =>
            item.modelWithSkillPacks.map((model) => model.name)
          )
        ),
      ]
    },
    skillPacksByModel() {
      return this.tableData.reduce((acc, item) => {
        item.modelWithSkillPacks.forEach(
          ({ name, skill_packs: skillPacks }) => {
            acc[name] = [
              ...(acc[name] || []),
              ...skillPacks.map((pack) => pack.name),
            ]
          }
        )
        return acc
      }, {})
    },
    visibleColumns() {
      if (this.selectedModel === 'all') {
        const modelsName = this.tableData.reduce((acc, item) => {
          item.modelWithSkillPacks.forEach((model) => {
            acc.push(model.name)
          })

          return acc
        }, [])

        return this.isEnabledPotentialMatch
          ? new Set(['Match Perfil', ...modelsName])
          : new Set([...modelsName])
      }

      return [...new Set(this.skillPacksByModel[this.selectedModel] || [])]
    },
    filteredData() {
      return this.tableData
        .filter((item) =>
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .map((item) => {
          const flatRow = { name: item.name }
          if (this.selectedModel === 'all') {
            flatRow['Match Perfil'] = item.occupationMatch ?? null
            item.modelWithSkillPacks.forEach((model) => {
              flatRow[model.name] = model.averageMatch
            })
          } else {
            item.modelWithSkillPacks.forEach((model) => {
              model.skill_packs.forEach((pack) => {
                flatRow[pack.name] = pack.match
              })
            })
          }
          return flatRow
        })
    },
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.perPage = 3
      this.paginated = true
    }
  },
  methods: {
    getCellClass(value) {
      if (value >= 75) return 'high-gap'
      if (value >= 30) return 'medium-gap'
      if (value >= 0) return 'low-gap'
      return ''
    },
  },
}
