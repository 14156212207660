const Request = require('@/repositories/request')
const { encodeQueryParams } = require('~/util/query')
class EducationalCareer extends Request {
  /**
   *
   * @param {int[]} ids
   * @returns {*}
   */
  findAnyWithIds(ids) {
    return this.$strapi.$http.$get(
      `/educational-careers?${encodeQueryParams({ id: ids })}`
    )
  }
}

module.exports = EducationalCareer
