//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    typeIcon: {
      type: String,
      default: 'bar',
    },
    label: {
      type: String,
      default: 'Item name',
    },
    color: {
      type: String,
      default: 'black',
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    textColor: {
      type: String,
      default: '#4a4a4a',
    },
    colorInactive: {
      type: String,
      default: '#bdbdbd',
    },
  },
}
