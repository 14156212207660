const Request = require('@/repositories/request')
class CompanyConfigs extends Request {
  getConfigsByCompany() {
    return this.$strapi.$http.get(`/company-configs/by-company`)
  }

  getConfigsByJobSeekerFair(jobSeekerFairId) {
    return this.$strapi.$http.get(
      `/company-configs/by-job-seeker-fair/${jobSeekerFairId}`
    )
  }
}

module.exports = CompanyConfigs
