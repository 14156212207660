import { render, staticRenderFns } from "./MatchPotential.vue?vue&type=template&id=74eebd9d&scoped=true&"
import script from "./MatchPotential.vue?vue&type=script&lang=js&"
export * from "./MatchPotential.vue?vue&type=script&lang=js&"
import style0 from "./MatchPotential.vue?vue&type=style&index=0&id=74eebd9d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74eebd9d",
  null
  
)

export default component.exports