//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GraphicCard from '@/components/DeliveryResults/GraphicCard.vue'
import handleErrorMixin from '@/mixins/handleErrorMixin'
export default {
  name: 'MatchByAge',
  components: {
    GraphicCard,
  },
  mixins: [handleErrorMixin],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    occupationId: {
      type: Number,
      default: 0,
    },
  },
  async fetch() {
    await this.fetchData()
  },
  data() {
    return {
      option: undefined,
      axis: [],
      series: [],
      baseOptions: {
        grid: {
          left: 0,
          right: 0,
          top: 24,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 10,
            interval: 0,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          valueFormatter: (value) => `Match promedio\n${value}%`,
        },
        series: [
          {
            data: [],
            type: 'bar',
            color: '#2CD5C4',
          },
        ],
      },
    }
  },
  computed: {
    options() {
      return this.getOptions()
    },
  },
  watch: {
    occupationId: {
      handler() {
        this.fetchData()
      },
    },
    projectId: {
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        const data = await this.$repository.project.getMatchByAge(
          this.projectId,
          this.occupationId
        )
        const dataByAge = this.formatDataByAgeToChart(data)
        this.axis = dataByAge.axis
        this.series = dataByAge.series
      } catch (error) {
        this.handleError(
          error,
          this.$t('projects.detail.matchPotential.chartByAge.errorMessage')
        )
      }
    },
    formatDataByAgeToChart(data) {
      if (data.length === 0) return { axis: [], series: [] }
      return {
        axis: data.map(({ label }) => label),
        series: data.map((element) => Math.round(element.match_value)),
      }
    },
    getOptions() {
      return {
        ...this.baseOptions,
        xAxis: {
          ...this.baseOptions.xAxis,
          data: this.axis,
        },
        series: [
          {
            ...this.baseOptions.series[0],
            data: this.series,
          },
        ],
      }
    },
  },
}
