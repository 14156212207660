//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NewProjectsWizardButtons from '@/components/Projects/NewProjectWizardButtons.vue'
import MessageWithoutHeader from '@/components/Shared/MessageWithoutHeader.vue'
import ModalAddUsers from '@/components/Projects/users/ModalAddUsers.vue'
import ModalErrorUsers from '@/components/Projects/users/ModalErrorUsers.vue'
import ROLES from '@/constants/roles'
import ModalUsersWithoutEmail from '@/components/Projects/users/ModalUsersWithoutEmail.vue'
import { mapGetters } from 'vuex'
import PROJECT_CONSTANTS from '@/constants/projects'
import MassiveImportUserModal from '~/components/Projects/users/MassiveImportUserModal.vue'
import CustomInvitationModal from '~/components/Projects/users/CustomInvitationModal.vue'
import ProjectUserList from '~/components/Projects/users/ProjectUserList.vue'
import MassiveImportInfoUserModal from '~/components/Projects/users/MassiveImportInfoUserModal.vue'

export default {
  name: 'StepThree',
  components: {
    NewProjectsWizardButtons,
    MessageWithoutHeader,
    ProjectUserList,
    ModalAddUsers,
    ModalErrorUsers,
    ModalUsersWithoutEmail,
    MassiveImportUserModal,
    CustomInvitationModal,
    MassiveImportInfoUserModal,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    await this.getEmployeesInProject()
    this.$store.commit('loading', false)
  },
  data: () => ({
    isShowModalAddUsers: false,
    isShowCustomMessage: false,
    isShowModalErrorUsers: false,
    isShowModalUsersWithoutEmail: false,
    isShowModalMassiveImportUser: false,
    isShowModalInfoMassiveImportUser: false,
    textModalErrorUsers: '',
    employees: [],
    employees_in_project: [],
    term: '',
    usersInfo: [],
  }),
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    showProjectType() {
      return !(
        (this.getBaseProject.objective?.value === PROJECT_CONSTANTS.OTHERS &&
          this.getBaseProject.objective_detail) ||
        (this.getBaseProject.objective?.value &&
          this.getBaseProject.objective?.value !== PROJECT_CONSTANTS.OTHERS)
      )
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
    handleContinue() {
      this.$emit('next')
    },
    toogleModalAddUsers() {
      this.isShowModalAddUsers = !this.isShowModalAddUsers
    },
    toogleModalCustomMessage() {
      this.isShowCustomMessage = !this.isShowCustomMessage
    },
    async finishSaveUser() {
      this.isShowModalAddUsers = !this.isShowModalAddUsers
      await this.getEmployeesInProject()
      this.$store.commit('loading', false)
    },
    handleMassiveModel() {
      this.toogleModalAddUsers()
      this.toggleMassiveImportModal()
    },
    toogleModalErrorUsers() {
      this.isShowModalErrorUsers = !this.isShowModalErrorUsers
    },
    toogleModalUsersWithoutEmail() {
      this.isShowModalUsersWithoutEmail = !this.isShowModalUsersWithoutEmail
    },
    toggleMassiveImportModal() {
      this.isShowModalMassiveImportUser = !this.isShowModalMassiveImportUser
    },
    toggleMassiveInfoImportModal() {
      this.isShowModalInfoMassiveImportUser = !this
        .isShowModalInfoMassiveImportUser
    },
    async toggleModalConfirmRegisterMassiveUser() {
      this.toggleMassiveInfoImportModal()
      await this.getEmployeesInProject()
      this.$store.commit('loading', false)
    },
    async getEmployees(companyId) {
      return await this.$repository.user.find({
        _where: {
          company: companyId,
          'role.type': ROLES.EMPLOYEE,
        },
        _limit: -1,
      })
    },
    handleUserWithError(message) {
      this.textModalErrorUsers = message
      this.isShowModalErrorUsers = true
    },
    handleUserWithoutEmailConfirm() {
      this.isShowModalUsersWithoutEmail = true
    },
    handleCreateUserWithoutEmail() {
      this.$refs.modalAddUsers.handleCreateUserWithoutEmail()
    },
    async getEmployeesInProject() {
      this.$store.commit('loading', true)
      this.employees = await this.getEmployees(this.$strapi.user.company.id)
      const tempUsers = await this.$repository.user.find({
        _where: {
          company: this.$strapi.user.company.id,
          'role.type': ROLES.EMPLOYEE,
          project: this.getBaseProject.id,
        },
        _limit: -1,
        _sort: 'created_at:DESC',
      })

      const employeeIds = tempUsers.map((employee) => employee.id)

      const tempInvitations = await this.$repository.invitation.findAll({
        _where: {
          user: employeeIds,
        },
      })

      this.employees_in_project = tempUsers.map((employee) => {
        const invitation = tempInvitations.find(
          (invitation) => invitation.user === employee.id
        )
        return {
          ...employee,
          invitation: invitation || null,
        }
      })
      this.$store.commit('loading', false)
    },
    sendUsersDetails(users) {
      this.usersInfo = users
      this.toggleMassiveImportModal()
      this.toggleMassiveInfoImportModal()
    },
  },
}
