//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lastEvaluateMatchMixin from '@/mixins/lastEvalutateMatchMixin'
import { SECTIONS } from '~/constants/questionary'
import { showErrorNotification } from '~/util/notifications'
import SKILL_MORPHS from '~/constants/skill-morphs'

export default {
  name: 'NewQuestionaryWizard',
  mixins: [lastEvaluateMatchMixin],
  props: {
    questionary: {
      type: Object,
      default: () => {},
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    this.userSkillIds = await this.getUserSkillsIds()
    this.$store.commit('loading', false)
  },
  data() {
    return {
      userSkillIds: [],
      SECTIONS_CONSTANT: SECTIONS,
    }
  },
  computed: {
    isInstructionSection: () => {
      return this.questionary?.section === SECTIONS.INSTRUCTIONS
    },
    userId() {
      return this.$strapi.user.id
    },
    skillsToValidate() {
      return (
        this.questionary.skills_to_validate[this.questionary.page - 1] || []
      )
    },
    title() {
      if (this.questionary?.total_pages === 1) {
        return this.$t('questionaryModels.validateSkills.start')
      }
      const finish = this.questionary?.page === this.questionary?.total_pages
      if (this.questionary?.page > 1 && !finish) {
        return this.$t('questionaryModels.validateSkills.inProgress')
      }
      return finish
        ? this.$t('questionaryModels.validateSkills.finish')
        : this.$t('questionaryModels.validateSkills.start')
    },
  },
  methods: {
    startQuestionary() {
      this.$emit('update', { section: SECTIONS.MODEL_SKILLS })
    },
    onPreviousStep() {
      if (this.questionary.page === 1) {
        this.$emit('update', { section: SECTIONS.INSTRUCTIONS })
        return
      }
      if (this.questionary.page <= this.questionary.total_pages) {
        this.skillsToValidate = this.questionary.skills_to_validate[
          this.questionary.page - 1
        ]
      }
      this.$emit('update', { page: this.questionary.page - 1 })
    },
    onNextStep() {
      this.page = this.questionary.page + 1
      if (this.page > this.questionary.total_pages) {
        this.evaluateMatchModelUser(this.questionary.user.id)
        this.$emit('update', { section: SECTIONS.FINISH, is_finish: true })
        return
      }

      if (this.questionary.page <= this.questionary.total_pages) {
        this.skillsToValidate = this.questionary.skills_to_validate[
          this.questionary.page - 1
        ]
      }
      this.$emit('update', { page: this.questionary.page + 1 })
    },
    async getUserSkillsIds() {
      const skillMorphs = await this.$repository.skillMorph.findAllWithSkills({
        user: this.userId,
        has_skill: true,
        _limit: -1,
      })
      return skillMorphs.map((skillMorph) => skillMorph.skill.id)
    },
    async addSkill(id) {
      await this.$repository.skillMorph.save({
        skill: {
          id,
        },
        user: this.userId,
        related_id: this.questionary.id,
        related_type: SKILL_MORPHS.ENTITY_TYPES.MODEL_QUESTIONARIES,
        origin_type: SKILL_MORPHS.ORIGIN_TYPES.SUGGEST,
      })
      this.userSkillIds.push(id)
    },
    async deleteSkill(id) {
      const skillMorphToRemove = {
        skill: id,
        user: this.userId,
        related_id: this.questionary.id,
        related_type: SKILL_MORPHS.ENTITY_TYPES.MODEL_QUESTIONARIES,
      }
      await this.$repository.skillMorph.remove(skillMorphToRemove)
      this.userSkillIds = this.userSkillIds.filter((skillId) => skillId !== id)
    },
    async handleChangeItem({ id, status }) {
      try {
        if (status) {
          await this.addSkill(id)
        } else {
          await this.deleteSkill(id)
        }
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error')
      }
    },
  },
}
