//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Tag from '@/components/Shared/Tag'

export default {
  name: 'InvitationListMassiveResend',
  components: { TableEmptyData, Tag },
  filters: {
    styleForActiveRow(isActive) {
      if (isActive) {
        return 'is-info is-light'
      }
      return 'is-success is-light'
    },
    textForActiveRow(isActive) {
      if (isActive) {
        return 'Pendiente'
      }
      return 'Aceptada'
    },
    fullNames(user) {
      return `${user?.names} ${user?.lastnames}`
    },
    showEmail(user) {
      return user?.email
    },
    showShortEmail(user) {
      return user?.email?.length > 20
        ? `${user?.email?.substring(0, 20)}...`
        : user?.email
    },
  },
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    totalPending: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  data() {
    return {
      currentPage: 1,
      checkedRows: [],
    }
  },
  computed: {
    resendEmails: {
      get() {
        return this.$store.state.emails.resendEmails
      },
      set(rows) {
        this.$store.commit('emails/setResendEmails', rows)
      },
    },
    resendAll: {
      get() {
        return this.$store.state.emails.resendAll
      },
      set(data) {
        this.$store.commit('emails/setResendAll', data)
      },
    },
    isVisibleSelectedAll() {
      const resendPending = this.data.filter((c) => c.active === true)
      if (this.resendEmails.length === resendPending.length) {
        return true
      }
      return false
    },
    showUsersAndAction() {
      if (this.resendAll) {
        return {
          users: this.totalPending,
          message: 'Anular seleccción',
        }
      }
      return {
        users: this.resendEmails.length,
        message: `Seleccionar los ${this.totalPending} usuarios restantes`,
      }
    },
  },
  methods: {
    resendInvitation({ id, user }) {
      this.$emit('resend-invitation', { id, email: user?.email })
    },
    onPageChange(page) {
      this.currentPage = page
      this.$emit('on-page-change', page)
      this.$store.commit('emails/clearResendEmails')
      this.$store.commit('emails/setResendAll', false)
    },
    OnRowChecked(data) {
      this.$store.commit('emails/setResendEmails', data)
      this.$emit('on-row-checked', data)
      this.$store.commit('emails/setResendAll', false)
    },
    selectedAllEmails() {
      this.$store.commit('emails/setResendAll', !this.resendAll)
    },
  },
}
