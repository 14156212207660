//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GraphicCard from '@/components/DeliveryResults/GraphicCard.vue'
import GENDERS from '@/constants/genders'
import handleErrorMixin from '@/mixins/handleErrorMixin'
export default {
  name: 'MatchByGender',
  components: {
    GraphicCard,
  },
  mixins: [handleErrorMixin],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    occupationId: {
      type: Number,
      default: 0,
    },
  },
  async fetch() {
    await this.fetchData()
  },
  data() {
    return {
      option: undefined,
    }
  },
  watch: {
    occupationId: {
      handler() {
        this.fetchData()
      },
    },
    projectId: {
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        const data = await this.$repository.project.getMatchByGender(
          this.projectId,
          this.occupationId
        )
        const dataByAge = this.formatDataByGenderToChart(data)
        this.option = this.buildOptionBarChart(dataByAge)
      } catch (error) {
        this.handleError(
          error,
          this.$t('projects.detail.matchPotential.chartByGender.errorMessage')
        )
      }
    },
    formatDataByGenderToChart(data) {
      const GENDER_LABELS = {
        [GENDERS.FEMALE]: this.$t('commons.genders.female'),
        [GENDERS.MALE]: this.$t('commons.genders.male'),
        [GENDERS.OTHERS]: this.$t('commons.genders.others'),
        [GENDERS.NOT_SPECIFIED]: this.$t('commons.genders.notSpecified'),
      }
      return {
        axis: data.map(({ gender }) => GENDER_LABELS[gender]),
        series: data.map((element) => Math.round(element.match_value)),
      }
    },
    buildOptionBarChart({ axis, series }) {
      return {
        grid: {
          left: 0,
          right: 0,
          top: 24,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: axis,
          axisLabel: {
            fontSize: 10,
            interval: 0,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          valueFormatter: (value) => `Match promedio\n${value}%`,
        },
        series: [
          {
            data: series,
            type: 'bar',
            color: '#2CD5C4',
          },
        ],
      }
    },
  },
}
