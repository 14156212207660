//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PERMISSION_LABELS } from '@/constants/permission-labels'
import moment from 'moment'
import Field from '@/components/Perfil/Field'
import CustomButton from '@/components/Perfil/Button'
import OpportunitiesList from '@/components/PotentialWorker/OpportunitiesList'
import WorkerSkillsInventory from '../SkillsInventory/WorkerSkillsInventory'
import ExperiencesTable from '~/components/PotentialWorker/ExperiencesTable'
import PotentialWorkerSkillsTable from '~/components/PotentialWorker/PotentialWorkerSkillsTable'
import { capitalizeWord } from '~/util/string'

export default {
  components: {
    Field,
    CustomButton,
    OpportunitiesList,
    WorkerSkillsInventory,
    ExperiencesTable,
    PotentialWorkerSkillsTable,
  },
  props: {
    userId: {
      type: Number,
      default: () => 0,
    },
    sortedOpportunities: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: () => {},
    },
    experienceWorks: {
      type: Array,
      default: () => [],
    },
    experienceEducationals: {
      type: Array,
      default: () => [],
    },
    experienceOthers: {
      type: Array,
      default: () => [],
    },
    skills: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: () => '',
    },
    jobStatusLabel: {
      type: String,
      default: () => '',
    },
    isActiveDetailExperience: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    const [
      { enabled: isEnabledPotentialMatch },
      { enabled: isEnabledInventorySkills },
    ] = await this.$permissions.allow([
      PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
      PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    ])
    this.isEnabledPotentialMatch = isEnabledPotentialMatch
    this.isEnabledInventorySkills = isEnabledInventorySkills
  },
  data() {
    return {
      activeTab: 0,
      personalData: [],
      isMountend: false,
      isEnabledPotentialMatch: false,
      isEnabledInventorySkills: false,
    }
  },
  computed: {
    personalDatas(context) {
      return [
        [
          {
            name: 'profile.personalData.form.names.label',
            value: context.person.names,
          },
          {
            name: 'profile.personalData.form.lastnames.label',
            value: context.person.lastnames,
          },
          {
            name: 'profile.personalData.form.idNumber',
            value: context.person.identification_number,
          },
          {
            name: 'profile.personalData.form.laboralSituation',
            value: this.jobStatusLabel,
          },
          {
            name: 'profile.personalData.form.position.label',
            value: context.person?.roleDescription
              ? capitalizeWord(context.person.roleDescription)
              : '',
          },

          {
            name: 'profile.accessData.form.email',
            value: context.person.email,
          },
          {
            name: 'profile.personalData.form.phone.label',
            value: context.person.phone,
          },
          {
            name: 'profile.personalData.form.birthdate.label',
            value: context.person.birthdate
              ? moment(context.person.birthdate).format('DD/MM/YYYY')
              : '',
          },
          ...context.person.addresses.map((address) => {
            return {
              name: `profile.addresses.field.${address.use}`,
              value: `${address.text}, ${address?.location?.name}`,
            }
          }),
        ],
      ]
    },
  },
  mounted() {
    this.isMountend = true
  },
  methods: {
    getEducationalExperienceName(row) {
      return (
        row.occupation?.title ?? row.area_educational?.name ?? row.study_name
      )
    },
    openWorkExperience(row) {
      this.$router.push(`/experiencias/laboral/${row.id}`)
    },
    openEducationalExperience(row) {
      this.$router.push(`/experiencias/educativa/${row.id}`)
    },
    openOtherExperience(row) {
      this.$router.push(`/experiencias/otra/${row.id}`)
    },
  },
}
