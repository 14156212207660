//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GraphicCard from '@/components/DeliveryResults/GraphicCard.vue'
import GENDERS from '@/constants/genders'
import handleErrorMixin from '@/mixins/handleErrorMixin'
export default {
  components: {
    GraphicCard,
  },
  mixins: [handleErrorMixin],
  props: {
    skillStats: {
      type: Object,
      default: () => ({
        totalsByAge: [],
        totalsByGender: [],
      }),
    },
  },
  fetch() {
    try {
      const { dataByAge, dataByGender } = this.getFormatSkillStats(
        this.skillStats
      )
      this.optionByAgeChart = this.buildOptionBarChart(dataByAge)
      this.optionByGenderChart = this.buildOptionBarChart(dataByGender)
    } catch (error) {
      this.handleError(
        error,
        'Ocurrió un error al procesar los datos de habilidades'
      )
    }
  },
  data: () => ({
    optionByAgeChart: undefined,
    optionByGenderChart: undefined,
  }),
  watch: {
    data: {
      handler: 'fetch',
      deep: true,
    },
  },
  methods: {
    getFormatSkillStats(skillStats) {
      const dataByAge = this.formatDataByAgeToChart(skillStats.totalsByAge)
      const dataByGender = this.formatDataByGenderToChart(
        skillStats.totalsByGender
      )
      return { dataByAge, dataByGender }
    },
    formatDataByAgeToChart(data) {
      if (data.length === 0) return { axis: [], series: [] }
      return {
        axis: data.map(({ label }) => label),
        series: data.map(({ total }) => total),
      }
    },
    formatDataByGenderToChart(data) {
      const GENDER_LABELS = {
        [GENDERS.FEMALE]: this.$t('commons.genders.female'),
        [GENDERS.MALE]: this.$t('commons.genders.male'),
        [GENDERS.OTHERS]: this.$t('commons.genders.others'),
        [GENDERS.NOT_SPECIFIED]: this.$t('commons.genders.notSpecified'),
      }
      return {
        axis: data.map(({ gender }) => GENDER_LABELS[gender]),
        series: data.map(({ total }) => total),
      }
    },
    buildOptionBarChart({ axis, series }) {
      return {
        grid: {
          left: 0,
          right: 0,
          top: 24,
          bottom: 0,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: axis,
          axisLabel: {
            fontSize: 10,
            interval: 0,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          valueFormatter: (value) => `Total de habilidades: ${value}`,
        },
        series: [
          {
            data: series,
            type: 'bar',
            color: '#2CD5C4',
          },
        ],
      }
    },
  },
}
