//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isMatch } from '@/util/string'
import ChevronRight from 'vue-material-design-icons/ChevronRight'
import PROJECT from '@/constants/projects'
import { TYPES } from '@/constants/project-types'
import RobotIcon from '~/assets/svg/robot-icon.svg'

export default {
  components: {
    ChevronRight,
    RobotIcon,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      projectTypeLabels: {
        [TYPES.RESKILLING]: this.$t('projects.types.reskilling'),
        [TYPES.UPSKILLING]: this.$t('projects.types.upskilling'),
        [TYPES.SELFDIAGNOSIS]: this.$t('projects.types.selfdiagnosis'),
        [TYPES.MODELS]: this.$t('projects.types.models'),
      },
    }
  },
  computed: {
    filteredProjects() {
      return this.projects.filter((project) => {
        return (
          (this.filters.text === '' ||
            isMatch(this.filters.text, project.name) ||
            project.name
              .toLowerCase()
              .includes(this.filters.text.toLowerCase())) &&
          (this.filters.status === null ||
            this.filters.status === '' ||
            this.filters.status === project.status)
        )
      })
    },
  },
  methods: {
    getClassByStatus(status) {
      return PROJECT[status].class
    },
    getNameByStatus(status) {
      return PROJECT[status].name
    },
    isFinished(status) {
      return status === PROJECT.FINISHED.value
    },
    openProjectDetail(project) {
      if (
        project.status === PROJECT.FINISHED.value &&
        // Fecha de pase a producción de breaking changes de nuevos reportes
        new Date(project.finished_at) <= new Date('2025-04-01')
      ) {
        // Los proyectos con fecha anterior a este pase, usan la siguiente vista
        // para evitar errores de compatibilidad
        this.$router.push(`/mis-proyectos/antiguo/${project.id}`)
      } else if (project.status === PROJECT.DRAFT.value) {
        this.$router.push(`/mis-proyectos/nuevo?code=${project.code}`)
      } else {
        this.$router.push(`/mis-proyectos/${project.id}`)
      }
    },
  },
}
